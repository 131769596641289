import { useCallback, useEffect, useState } from 'react';
import { z, ZodObject, ZodRawShape, ZodSchema, ZodTypeAny } from 'zod';

export const useForm = <T extends object>(Model, initialValue: T) => {
  const [value, setValue] = useState(initialValue);
  const [valid, setValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState({});
  const [toucheds, setToucheds] = useState({});

  useEffect(() => {
    const { success, error } = Model.safeParse(value);

    setValid(success);

    let _errors = {};
    if (!success) {
      _errors = Object.fromEntries(error.issues.map(({ path, message }) => [path[0], message]));
    }

    setErrors(_errors);
    setStatus(Object.fromEntries(Object.keys(Model.shape).map((key) => [key, _errors[key] ? 'error' : ''])));
  }, [value]);

  const parse = useCallback((): { data: T; success: boolean; error: any } => {
    return Model.safeParse(value);
  }, [initialValue, setToucheds]);

  const setField = useCallback(
    (fieldName) => {
      return (feildValue) => {
        setValue((_value) => ({ ..._value, [fieldName]: feildValue }));
        setTimeout(() => {
          setToucheds((_toucheds) => ({ ..._toucheds, [fieldName]: true }));
        }, 0);
      };
    },
    [setValue, setToucheds]
  );

  const setAllTouched = useCallback(() => {
    setToucheds(Object.fromEntries(Object.keys(initialValue).map((key) => [key, true])));
  }, [initialValue, setToucheds]);

  const props = useCallback(
    (fieldName) => {
      return {
        value: value[fieldName],
        status: toucheds[fieldName] ? status[fieldName] : '',
        //errorMessage: errors[fieldName],
        onChange: setField(fieldName),
        //onSelectionChange: setField(fieldName),
      };
    },
    [value, status, errors, toucheds, setField]
  );

  const reset = useCallback(() => {
    setValue(initialValue);
    setToucheds({});
  }, [setValue]);

  return { value, valid, errors, toucheds, status, props, parse, setValue, setField, setAllTouched, reset };
};
