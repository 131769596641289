import { useCallback, useState } from 'react';
import { useForm } from './useForm';

export const useFillForm = <T extends object>(Model: any, initialValue: T) => {
  const [selected, setSelected] = useState<any>();
  const form = useForm<T>(Model, initialValue);

  const clear = useCallback(() => {
    form.reset();
    setSelected(null);
  }, [form]);

  const fill = useCallback(
    (values) => {
      setSelected(values);
      form.setValue({ ...values });
    },
    [form]
  );

  return { form, selected, setSelected, clear, fill };
};
