import { useFetch } from '../hooks';
import { SiparisModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/bhr/siparis/list', { method: 'POST', ...config }),
  pasif: async (order: SiparisModel[]) => request('/bhr/siparis/pasif', 'POST', order),
  satis: async (order: SiparisModel[]) => request('/bhr/siparis/satis', 'POST', order),
  satisIptal: async (order: SiparisModel[]) => request('/bhr/siparis/satis-iptal', 'POST', order),
  iade: async (order: SiparisModel[]) => request('/bhr/siparis/iade', 'POST', order),
  iadeIptal: async (order: SiparisModel[]) => request('/bhr/siparis/iade-iptal', 'POST', order),
  hurda: async (order: SiparisModel[]) => request('/bhr/siparis/hurda', 'POST', order),
  hurdaIptal: async (order: SiparisModel[]) => request('/bhr/siparis/hurda-iptal', 'POST', order),
  odemeYap: async (order: SiparisModel[]) => request('/bhr/siparis/odeme-yap', 'POST', order),
  odemeGeriAl: async (order: SiparisModel[]) => request('/bhr/siparis/odemeyi-iptal-et', 'POST', order),
  save: async (order: SiparisModel[]) => request('/bhr/siparis/kayit', 'POST', order),
  update: async (order: SiparisModel) => request('/bhr/siparis/guncelle', 'POST', order),
};
