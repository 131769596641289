import React, { useCallback } from 'react';
import { Input as InputAnt } from 'antd';

type Props = {
  form: any;
  name: string;
  disabled?: boolean;
  ascii?: boolean;
};

export const Input = ({ form, name, disabled, ascii }: Props) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (ascii && !/[a-zA-Z0-9_]/.test(event.key)) {
        event.preventDefault();
      }
    },
    [ascii]
  );

  return (
    <div className="relative">
      <InputAnt className="md:max-w-[300px]" disabled={disabled} {...form.props(name)} onKeyDown={onKeyDown} onChange={(e) => form.setField(name)(e.target.value)} />
      {form.toucheds[name] && <small className="left-0 -bottom-4 text-red-500">{form.errors[name]}</small>}
    </div>
  );
};
