import React, { useContext } from 'react';

import { HomeOutlined } from '@ant-design/icons';
import { ROL } from '../types';
import { UserContext } from '../contexts/UserContext';
import { AdminHome } from './AdminHome';
import { TedHome } from './TedHome';

export const Home = () => {
  const kullaniciModel = useContext(UserContext);

  if (kullaniciModel.kullaniciTipi === ROL.ADMIN) {
    return <AdminHome />;
  }

  if (kullaniciModel.kullaniciTipi === ROL.TEDARIKCI) {
    return <TedHome />;
  }

  return <div></div>;
};

Home.path = '/';
Home.title = 'Anasayfa';
Home.group = 'flat';
Home.roles = [ROL.ADMIN, ROL.DEPOCU, ROL.TEDARIKCI];
Home.icon = <HomeOutlined />;
