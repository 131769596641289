import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileExcelOutlined,
  MailOutlined,
  PlusCircleOutlined,
  ProfileOutlined,
  RetweetOutlined,
  RollbackOutlined,
  SendOutlined,
  StepBackwardOutlined,
} from '@ant-design/icons';
import { Button, DatePicker as DatePickerAnt, Input, Modal, Select, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import * as API from '../../api';

import { z } from 'zod';
import { DocViewer, DraggableModal, InputNumber, Input as InputComp, OrderUpdate, Toolbar, ToolbarLeft, ToolbarRight, Select as SelectComp, DatePicker } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useForm, useOrderList, useWindowSize } from '../../hooks';
import { EVET_HAYIR, ROL, SIPARIS_STATU, SiparisModel } from '../../types';
import { excelExportOrderList } from '../../utils';
import { NewOrder } from './NewOrder';

const SatisForm = z.object({
  satisFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  satisBhrPazaryeriModel: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).optional(),
  satisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).optional(),
  satisSiparisNo: z.string().optional(),
});

const IptalForm = z.object({
  iptalAciklama: z.string().optional(),
});

const FaturaTipForm = z.object({
  faturaTipiId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const OrderListStep1 = () => {
  const { sm } = useWindowSize();
  const kullaniciModel = useContext(UserContext);

  const [markets = []] = API.MARKETS.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [categories = []] = API.CATEGORY.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [brands = []] = API.BRAND.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehouses = []] = API.WAREHOUSES.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const [billtypes = []] = API.BILLTYPE.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const [fillOrder, setFillOrder] = useState<SiparisModel | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isSatisModalOpen, setIsSatisModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isEfModalOpen, setIsEfModalOpen] = useState(false);

  const cancelForm = useForm(IptalForm, {
    iptalAciklama: '',
  });

  const satisForm = useForm(SatisForm, {
    satisFiyat: undefined,
    satisBhrPazaryeriModel: undefined,
    satisSiparisNo: '',
    satisTarihi: new Date(),
  });

  const faturaTipiForm = useFillForm(FaturaTipForm, {
    faturaTipiId: undefined,
  });

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
    setIsUpdateModalOpen(false);
    setIsSatisModalOpen(false);
    setIsCancelModalOpen(false);
    setIsEfModalOpen(false);
    satisForm.reset();
  }, [setIsModalOpen]);

  const {
    users = [],
    setSelectedUserId,
    siparisStatu,
    setSiparisStatu,
    odemeYapildi,
    odemeGeriAl,
    setOdemeYapildi,
    faturaOnay,
    setFaturaOnay,
    efaturaMi,
    setEfaturaMi,
    depoyeri,
    setDepoyeri,
    pazaryeri,
    setPazaryeri,
    kategori,
    setKategori,
    marka,
    setMarka,
    urun,
    setUrun,
    siparisNo,
    setSiparisNo,
    siparisId,
    setSiparisId,
    seriNo,
    setSeriNo,
    alisFaturaNo,
    setAlisFaturaNo,
    faturaTipiId,
    setFaturaTipiId,
    siparisTarihi,
    setSiparisTarihi,
    satisTarihi,
    setSatisTarihi,
    faturaTarihi,
    setFaturaTarihi,
    odemeTarih,
    setOdemeTarihi,
    isAdmin,
    list,
    loading,
    getList,
    columns,
    selectedKeyList,
    selectedList,
    onMultiSelectClick,
    pasif,
    satis,
    satisIptal,
    iade,
    iadeIptal,
    hurda,
    hurdaIptal,
    odemeYap,
    downloadAll,
    isPdf,
    doc,
    byteArray,
    isDocModalOpen,
    setIsDocModalOpen,
    getDoc,
  } = useOrderList();

  const satisHandleOk = useCallback(async () => {
    const { data, success } = satisForm.parse();
    if (success) {
      const satisTarihi = new Date(data.satisTarihi);
      satisTarihi.setHours(0);
      satisTarihi.setMinutes(0);
      satisTarihi.setSeconds(0);
      satisTarihi.setMilliseconds(0);

      const satisList = selectedList?.map((order) => ({
        ...order,
        satisFiyat: data.satisFiyat,
        satisBhrPazaryeriModel: { pazaryeriId: data.satisBhrPazaryeriModel },
        satisSiparisNo: data.satisSiparisNo,
      })) as SiparisModel[];

      await satis(satisList);

      handleCancel();
      satisForm.reset();
    } else {
      satisForm.setAllTouched();
    }
  }, [satisForm, selectedList]);

  const docHandleCancel = useCallback(() => {
    setIsDocModalOpen(false);
  }, [setIsDocModalOpen]);

  const excel = useCallback(() => {
    if (list && list.length > 0) {
      excelExportOrderList(list);
    }
  }, [list]);

  const cancelBill = useCallback(async () => {
    if (selectedList) {
      const { success, data } = cancelForm.parse();
      const siparisIdList = selectedList.map((order) => Number(order.siparisId));

      await API.FILES.cancel(siparisIdList, data.iptalAciklama);

      handleCancel();
      getList();
    }
  }, [selectedList, cancelForm]);

  const billTypeChange = useCallback(async () => {
    if (selectedList) {
      const { success, data } = faturaTipiForm.form.parse();
      const siparisIdList = selectedList.map((order) => Number(order.siparisId));

      if (data.faturaTipiId) {
        await API.FILES.faturaTipiGuncelle(siparisIdList, data.faturaTipiId);

        handleCancel();
        getList();
      }
    }
  }, [selectedList, cancelForm]);

  const productByCategory = products.filter((product) => product.bhrKategoriModel?.kategoriId === kategori && product.bhrMarkaModel?.markaId === marka);

  const selectedKeyListSt = (selectedKeyList || []) as React.Key[];

  const totalAmount = useMemo(() => {
    if (list && list.length > 0) {
      const amount = list.reduce((acc, item) => (item.alisFiyat ? item.alisFiyat + acc : acc), 0);

      return new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(amount);
    }
    return '0';
  }, [list]);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarRight classname="sm:flex-none">
          <Button size="small" onClick={() => getList()} icon={<RetweetOutlined />}>
            Sorgula
          </Button>
          <Button
            size="small"
            className=" flex-1 sm:flex-none"
            type="primary"
            onClick={() => {
              setFillOrder(null);
              setIsModalOpen(true);
            }}
            icon={<PlusCircleOutlined />}
          >
            Yeni Sipariş
          </Button>
          <Button
            size="small"
            className=" flex-1 sm:flex-none"
            type="primary"
            disabled={selectedList?.length !== 1}
            onClick={() => {
              if (selectedList && selectedList.length > 0) {
                setFillOrder(selectedList[0]);
                setIsModalOpen(true);
              }
            }}
            icon={<CopyOutlined />}
          >
            Kopyala
          </Button>
          <Button
            size="small"
            className=" flex-1 sm:flex-none"
            type="primary"
            disabled={isAdmin ? selectedList?.length !== 1 : selectedList?.length !== 1 || selectedList[0].bhrDepoyeriModel?.tedarikciGorsunMu === 'H'}
            onClick={() => {
              if (selectedList && selectedList.length > 0) {
                setFillOrder(selectedList[0]);
                setIsUpdateModalOpen(true);
              }
            }}
            icon={<EditOutlined />}
          >
            Güncelle
          </Button>
          <Button size="small" className="w-full" disabled={!(list && list.length > 0)} onClick={excel} icon={<FileExcelOutlined />}>
            Excel
          </Button>
          {isAdmin && (
            <>
              <Button
                size="small"
                className=" flex-1 sm:flex-none"
                type="primary"
                disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.siparisStatu === SIPARIS_STATU.SIP_DEPO))}
                onClick={() => setIsSatisModalOpen(true)}
                icon={<SendOutlined />}
              >
                Satış
              </Button>
              <Button
                size="small"
                className=" flex-1 sm:flex-none"
                type="primary"
                danger
                disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.siparisStatu === SIPARIS_STATU.SIP_SATIS))}
                onClick={() => satisIptal(selectedList)}
                icon={<RollbackOutlined />}
              >
                Satış İptal
              </Button>
              <Button
                size="small"
                className=" flex-1 sm:flex-none"
                type="primary"
                disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.odemesiYapildi === EVET_HAYIR.HAYIR))}
                onClick={() => odemeYap(selectedList)}
                icon={<MailOutlined />}
              >
                Ödeme Yap
              </Button>
              <Button
                size="small"
                className=" flex-1 sm:flex-none"
                type="primary"
                disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.odemesiYapildi === EVET_HAYIR.EVET))}
                onClick={() => odemeGeriAl(selectedList)}
                icon={<RollbackOutlined />}
              >
                Ödeme Geri Al
              </Button>
              <Button size="small" className=" flex-1 sm:flex-none" disabled={!(list && list.length > 0)} onClick={() => downloadAll(list)} icon={<DownloadOutlined />}>
                İndir
              </Button>
            </>
          )}

          <Button
            size="small"
            className="w-full"
            disabled={!(selectedList && selectedList.length === 1 && selectedList[0]?.alisFaturasiModel?.dokumanId)}
            onClick={getDoc}
            icon={<EyeOutlined />}
          >
            Görüntüle
          </Button>
          {isAdmin && (
            <>
              <Button
                size="small"
                className=" flex-1 sm:flex-none"
                type="primary"
                danger
                disabled={!Boolean(selectedList?.length)}
                onClick={() => pasif(selectedList)}
                icon={<DeleteOutlined />}
              >
                Sil
              </Button>
              <Button
                size="small"
                className=" flex-1 sm:flex-none"
                type="primary"
                danger
                disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.alisFaturasiOnaylandi === EVET_HAYIR.EVET))}
                onClick={() => setIsCancelModalOpen(true)}
                icon={<StepBackwardOutlined />}
              >
                Fatura İptal
              </Button>
            </>
          )}
        </ToolbarRight>
      </Toolbar>
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
          {isAdmin && (
            <Select
              className="w-full sm:w-64 md:max-w-[240px]"
              showSearch
              placeholder="Tedarikçi Seçiniz"
              options={users.map((user) => ({ ...user, label: `${user.kullaniciAdi} - ${user.ad} ${user.soyad}` }))}
              fieldNames={{ label: 'label', value: 'kullaniciId' }}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              allowClear
              onChange={setSelectedUserId}
              disabled={loading}
              loading={loading}
            ></Select>
          )}

          <Select
            className="w-full sm:w-64 md:max-w-[100px]"
            showSearch
            placeholder="Statü Seçiniz"
            options={[
              { value: SIPARIS_STATU.SIP_DEPO, label: 'DEPODA' },
              { value: SIPARIS_STATU.SIP_SATIS, label: 'SATILDI' },
              { value: SIPARIS_STATU.SIP_IADE, label: 'İADE' },
              { value: SIPARIS_STATU.SIP_HURDA, label: 'HURDA' },
            ]}
            fieldNames={{ value: 'value', label: 'label' }}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            allowClear
            value={siparisStatu}
            onChange={setSiparisStatu}
            disabled={loading}
            loading={loading}
          ></Select>

          <Select
            className="w-full sm:w-64 md:max-w-[160px]"
            showSearch
            placeholder="Ödeme Durumu"
            options={[
              { value: EVET_HAYIR.EVET, label: 'Ödeme Yapıldı' },
              { value: EVET_HAYIR.HAYIR, label: 'Ödeme Yapılmadı' },
            ]}
            fieldNames={{ value: 'value', label: 'label' }}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            value={odemeYapildi}
            onChange={setOdemeYapildi}
            allowClear
            disabled={loading}
            loading={loading}
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[160px]"
            showSearch
            placeholder="Fatura Durumu"
            options={[
              { value: EVET_HAYIR.EVET, label: 'Fatura Onaylı' },
              { value: EVET_HAYIR.HAYIR, label: 'Fatura Onaysız' },
            ]}
            fieldNames={{ value: 'value', label: 'label' }}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            value={faturaOnay}
            onChange={setFaturaOnay}
            allowClear
            disabled={loading}
            loading={loading}
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[160px]"
            showSearch
            placeholder="E-Fatura Mı"
            options={[
              { value: EVET_HAYIR.EVET, label: 'Evet' },
              { value: EVET_HAYIR.HAYIR, label: 'Hayır' },
            ]}
            fieldNames={{ value: 'value', label: 'label' }}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            value={efaturaMi}
            onChange={setEfaturaMi}
            allowClear
            disabled={loading}
            loading={loading}
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Depoyeri"
            options={warehouses}
            fieldNames={{ label: 'depoyeriAdi', value: 'depoyeriId' }}
            filterOption={(input, option) => (option?.depoyeriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={depoyeri}
            onChange={setDepoyeri}
            allowClear
            disabled={loading}
            loading={loading}
          ></Select>
          {isAdmin && (
            <>
              <Button
                size="small"
                className=" flex-1 sm:flex-none"
                type="primary"
                danger
                disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.siparisStatu === SIPARIS_STATU.SIP_DEPO))}
                onClick={() => iade(selectedList)}
                icon={<RollbackOutlined />}
              >
                İade
              </Button>
              <Button
                size="small"
                className=" flex-1 sm:flex-none"
                type="primary"
                danger
                disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.siparisStatu === SIPARIS_STATU.SIP_IADE))}
                onClick={() => iadeIptal(selectedList)}
                icon={<RollbackOutlined />}
              >
                İade İptal
              </Button>
              <Button
                size="small"
                className=" flex-1 sm:flex-none"
                type="primary"
                disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.alisFaturasiModel))}
                onClick={() => setIsEfModalOpen(true)}
                icon={<StepBackwardOutlined />}
              >
                Fatura Tipi Değiştir
              </Button>
            </>
          )}
        </ToolbarLeft>
      </Toolbar>
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
          <DatePickerAnt.RangePicker
            placeholder={['Sipariş Tar. Baş.', 'Bitiş Tar.']}
            value={siparisTarihi}
            onChange={(value) => setSiparisTarihi(value as any)}
            allowClear={true}
            className="w-full sm:w-72"
            locale={locale}
            format="DD.MM.YYYY"
            disabled={loading}
          />
          <DatePickerAnt.RangePicker
            placeholder={['Satış Tar. Baş.', 'Bitiş Tar.']}
            value={satisTarihi}
            onChange={(value) => setSatisTarihi(value as any)}
            allowClear={true}
            className="w-full sm:w-72"
            locale={locale}
            format="DD.MM.YYYY"
            disabled={loading}
          />
          <DatePickerAnt
            placeholder="Fatura Tarihi"
            value={faturaTarihi}
            onChange={(value) => setFaturaTarihi(value)}
            allowClear={true}
            className="w-full sm:w-40"
            picker="month"
            locale={locale}
            format="YYYY MMMM"
            disabled={loading}
          />
          <DatePickerAnt.RangePicker
            placeholder={['Ödeme Tar. Baş.', 'Bitiş Tar.']}
            value={odemeTarih}
            onChange={(value) => setOdemeTarihi(value as any)}
            allowClear={true}
            className="w-full sm:w-72"
            locale={locale}
            format="DD.MM.YYYY"
            disabled={loading}
          />
          <Select
            className="w-full sm:w-64 md:max-w-[160px]"
            showSearch
            placeholder="Fatura Tipi"
            options={billtypes}
            fieldNames={{ value: 'faturaTipiId', label: 'faturaTipiAdi' }}
            value={faturaTipiId}
            onChange={setFaturaTipiId}
            allowClear
            disabled={loading}
            loading={loading}
          ></Select>
          <Button
            size="small"
            className=" flex-1 sm:flex-none"
            type="primary"
            danger
            disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.siparisStatu === SIPARIS_STATU.SIP_DEPO))}
            onClick={() => hurda(selectedList)}
            icon={<RollbackOutlined />}
          >
            Hurda
          </Button>
          <Button
            size="small"
            className=" flex-1 sm:flex-none"
            type="primary"
            danger
            disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.siparisStatu === SIPARIS_STATU.SIP_HURDA))}
            onClick={() => hurdaIptal(selectedList)}
            icon={<RollbackOutlined />}
          >
            Hurda İptal
          </Button>
        </ToolbarLeft>
      </Toolbar>
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
          <Select
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Pazaryeri"
            options={markets}
            fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }}
            filterOption={(input, option) => (option?.pazaryeriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={pazaryeri}
            onChange={setPazaryeri}
            allowClear
            disabled={loading}
            loading={loading}
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Kategori"
            options={categories}
            fieldNames={{ label: 'kategoriAdi', value: 'kategoriId' }}
            filterOption={(input, option) => (option?.kategoriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={kategori}
            onChange={setKategori}
            allowClear
            disabled={loading}
            loading={loading}
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Marka"
            options={brands}
            fieldNames={{ label: 'markaAdi', value: 'markaId' }}
            filterOption={(input, option) => (option?.markaAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={marka}
            onChange={setMarka}
            allowClear
            disabled={loading}
            loading={loading}
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Ürün"
            options={productByCategory}
            fieldNames={{ label: 'urunAdi', value: 'urunId' }}
            filterOption={(input, option) => (option?.urunAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={urun}
            onChange={setUrun}
            allowClear
            disabled={loading}
            loading={loading}
          ></Select>
          <Input
            className="w-full sm:w-64 md:max-w-[200px]"
            placeholder="Sipariş No"
            value={siparisNo}
            onChange={(e) => setSiparisNo(e.target.value)}
            onBlur={() => getList()}
            allowClear
            disabled={loading}
          ></Input>
          <Input
            className="w-full sm:w-64 md:max-w-[120px]"
            placeholder="Kayıt No"
            value={siparisId}
            onChange={(e) => setSiparisId(e.target.value as unknown as number)}
            onBlur={() => getList()}
            allowClear
            disabled={loading}
          ></Input>
          <Input
            className="w-full sm:w-64 md:max-w-[120px]"
            placeholder="Seri No"
            value={seriNo}
            onChange={(e) => setSeriNo(e.target.value)}
            onBlur={() => getList()}
            allowClear
            disabled={loading}
          ></Input>
          <Input
            className="w-full sm:w-64 md:max-w-[120px]"
            placeholder="Fatura No"
            value={alisFaturaNo}
            onChange={(e) => setAlisFaturaNo(e.target.value)}
            onBlur={() => getList()}
            allowClear
            disabled={loading}
          ></Input>
        </ToolbarLeft>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={{
          defaultPageSize: 25,
          pageSizeOptions: [10, 25, 50, 100, 200],
          position: ['topRight'],
          showTotal: (total) => (
            <div>
              Toplam Kayıt : {total} - Toplam Tutar : {totalAmount}{' '}
            </div>
          ),
        }}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => {
            if (isAdmin) {
              const key = record.siparisId as React.Key;
              if (selectedKeyListSt.includes(key)) {
                onMultiSelectClick(selectedKeyListSt.filter((_key) => _key !== key));
              } else {
                onMultiSelectClick([...selectedKeyListSt, key]);
              }
            }
          },
        })}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedKeyList || [],
          onChange: onMultiSelectClick,
        }}
      />

      <Modal width={480} footer={null} cancelText="Kapat" open={isModalOpen} onCancel={handleCancel} bodyStyle={{ marginTop: 20 }}>
        <NewOrder
          key={list?.length}
          fillOrder={fillOrder}
          onSaveDone={() => {
            getList();
            setFillOrder(null);
            onMultiSelectClick([]);
            setIsModalOpen(false);
          }}
        />
      </Modal>
      <Modal width={480} footer={null} cancelText="Kapat" open={isUpdateModalOpen} onCancel={handleCancel} style={{ marginTop: -70 }} bodyStyle={{ marginTop: 20 }}>
        <OrderUpdate
          open={isUpdateModalOpen}
          order={fillOrder}
          onSave={async (order: SiparisModel) => {
            await API.ORDERS.update(order);
            setIsUpdateModalOpen(false);
            setFillOrder(null);
            onMultiSelectClick([]);
            getList();
          }}
        />
      </Modal>
      <DraggableModal width={300} title="Satış" okText="Kaydet" cancelText="Kapat" open={isSatisModalOpen} onOk={satisHandleOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Satış Fiyat :</label>
          <InputNumber form={satisForm} name="satisFiyat" min={1} />

          <label className="whitespace-nowrap">Satış Pazaryeri :</label>
          <SelectComp options={markets} fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }} form={satisForm} name="satisBhrPazaryeriModel" />

          <label className="whitespace-nowrap">Satış Sipariş No :</label>
          <InputComp form={satisForm} name="satisSiparisNo" />
        </div>
      </DraggableModal>
      <DraggableModal width={300} title="Fatura İptal" okText="İptal Et" cancelText="Kapat" okType="danger" open={isCancelModalOpen} onOk={cancelBill} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">İptal Açıklama :</label>
          <InputComp form={cancelForm} name="iptalAciklama" />
        </div>
      </DraggableModal>
      <DraggableModal width={300} title="Fatura Yükleme" okText="Kaydet" cancelText="Kapat" open={isEfModalOpen} onOk={billTypeChange} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Fatura Tipi :</label>
          <SelectComp options={billtypes} fieldNames={{ label: 'faturaTipiAdi', value: 'faturaTipiId' }} form={faturaTipiForm.form} name="faturaTipiId" />
        </div>
      </DraggableModal>
      {doc && (
        <Modal
          key={doc.dokumanId}
          width={'min-content'}
          footer={null}
          cancelText="Kapat"
          open={isDocModalOpen}
          onCancel={docHandleCancel}
          style={{ top: 40 }}
          bodyStyle={{ marginTop: 20, height: '86vh', overflow: 'auto' }}
        >
          {isPdf && <DocViewer doc={doc} byteArray={byteArray} />}
          {!isPdf && <img src={`data:image;base64,${byteArray}`}></img>}
        </Modal>
      )}
    </div>
  );
};

OrderListStep1.path = 'order-list-step-1';
OrderListStep1.title = 'Oluşturulanlar';
OrderListStep1.group = 'supplier';
OrderListStep1.roles = [ROL.TEDARIKCI, ROL.ADMIN];
OrderListStep1.icon = <ProfileOutlined />;
