import React from 'react';
import { InputNumber as InputNumberAnt } from 'antd';

type Props = {
  form: any;
  name: string;
  disabled?: boolean;
  min?: number;
  max?: number;
};

export const InputNumber = ({ form, name, min, max, disabled }: Props) => {
  return (
    <div className="relative md:max-w-[300px]">
      <InputNumberAnt className="w-full" decimalSeparator="," min={min} max={max} disabled={disabled} {...form.props(name)} />
      {form.toucheds[name] && <small className="left-0 -bottom-4 text-red-500">{form.errors[name]}</small>}
    </div>
  );
};
