import { useCallback, useState } from 'react';

export const useSingleSelect = <T>(key: keyof T, list: T[]) => {
  const [selected, setSelected] = useState<T>();

  const onSelectClick = useCallback(
    (newSelectedRowKeys: React.Key[]) => {
      if (newSelectedRowKeys?.length > 0) {
        setSelected(list.find((item) => item[key] === newSelectedRowKeys[0]));
      }
    },
    [list, setSelected]
  );

  return { selected, setSelected, onSelectClick };
};
