export const hashCode = (str: string) => {
  return String(
    Array.from(str).reduce(
      (s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0,
      0
    )
  );
};

export const objToHashSymbol = (obj: unknown) => {
  try {
    return Symbol.for(hashCode(JSON.stringify(obj)));

  } catch {
    return undefined;
  }
}
