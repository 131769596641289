import { Tag } from 'antd';
import React from 'react';

type Props = {
  record?: any;
};

export const DictTableStatus = ({ record }: Props) => {
  return (
    <Tag style={{ width: '100%', textAlign: 'center' }} color={record.aktifPasif === 'A' ? 'green' : 'red'}>
      {record.aktifPasif === 'A' ? 'AKTİF' : 'PASİF'}{' '}
    </Tag>
  );
};
