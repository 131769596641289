import { useFetch } from '../hooks';
import { KullaniciModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<KullaniciModel[]>('/kullanici/list-by-firma', { method: 'POST', ...config }),
  useFetchTedarikci: (config: UseFetchOptions) => useFetch<KullaniciModel[]>('/kullanici/aktif-tedarikci-list-by-firma', { method: 'POST', ...config }),
  login: async (user: KullaniciModel, options: RequestInit) => request('/kullanici/login', 'POST', user, null, options, true),
  save: async (user: KullaniciModel) => request('/kullanici/kayit', 'POST', user),
  update: async (user: KullaniciModel) => request('/kullanici/guncelle', 'POST', user),
  activePassive: async (user: KullaniciModel) => request('/kullanici/aktif-pasif', 'POST', user),
};
