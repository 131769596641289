import React from 'react';

type Props = {
  children: React.ReactNode;
  classname?: string;
};

export const Toolbar = ({ children, classname }: Props) => {
  return <div className={`bg-white flex items-center  justify-between gap-2 px-2 w-full h-12 white shadow border ${classname}`}>{children}</div>;
};

export const ToolbarLeft = ({ children, classname }: Props) => {
  return <div className={`flex items-center justify-start gap-2 ${classname}`}>{children}</div>;
};

export const ToolbarRight = ({ children, classname }: Props) => {
  return <div className={`flex items-center justify-start gap-2 ${classname}`}>{children}</div>;
};
