import { EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

type Props = {
  record?: any;
  fill: CallableFunction;
  remove: CallableFunction;
};

export const DictTableActions = ({ record, remove, fill }: Props) => {
  return (
    <div className="flex flex-col sm:flex-row gap-1">
      <Button block size="small" type="primary" icon={<EditOutlined />} onClick={() => fill(record)}>
        Düzenle
      </Button>
      <Button block size="small" danger={record.aktifPasif === 'A'} icon={record.aktifPasif === 'A' ? <EyeInvisibleOutlined /> : <EyeOutlined />} onClick={() => remove(record)}>
        {record.aktifPasif === 'A' ? 'Pasif Yap' : 'Aktif Yap'}
      </Button>
    </div>
  );
};
