import { Button, Card, Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as API from '../api';
import { auth, TheUser } from '../contexts/UserContext';

const oneHour = 1000 * 60 * 60;

export const Login = ({ setUser }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    try {
      const str = localStorage.getItem('stok.atlassoft');
      const strThen = localStorage.getItem('stok.atlassoft.then');
      const strLast = localStorage.getItem('stok.atlassoft.last');
      if (str && strThen && strLast) {
        const data = JSON.parse(str);
        const dataThen = JSON.parse(strThen);
        const dataLast = JSON.parse(strLast);
        if (data && dataThen && dataLast) {
          if (new Date().getTime() - dataLast <= oneHour) {
            Object.assign(TheUser, data);
            Object.assign(auth, dataThen);
            setUser(data);
          }
        }
      }
    } catch {}
  }, []);

  const login = useCallback(async () => {
    const response = await API.USERS.login(
      {
        kullaniciAdi: username,
        kullaniciSifre: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + btoa(`${username}:${password}`),
        },
      }
    );

    if (response.ok) {
      Object.assign(TheUser, response.data);
      Object.assign(auth, { basic: btoa(`${username}:${password}`) });
      localStorage.setItem('stok.atlassoft', JSON.stringify(TheUser));
      localStorage.setItem('stok.atlassoft.then', JSON.stringify(auth));
      setUser(response.data);
      navigate('/');
    } else {
    }
  }, [username, password]);

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-slate-300 ">
      <Card className="w-96">
        <div
          className="flex flex-col gap-0.5"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              login();
            }
          }}
        >
          <label>Kullanıcı Adı :</label>
          <Input value={username} onChange={(e) => setUsername(e.target.value)} />
          <label className="mt-4">Şifre :</label>
          <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />

          <Button block type="primary" className="mt-4" onClick={login}>
            Giriş Yap
          </Button>
        </div>
      </Card>
    </div>
  );
};
