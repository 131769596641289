import { CheckCircleOutlined, CloudUploadOutlined, EyeOutlined, FieldTimeOutlined, RetweetOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker as DatePickerAnt, Input, Modal, Select as SelectAnt, Table, Upload, UploadFile, UploadProps } from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import React, { useCallback, useContext, useState } from 'react';

import { z } from 'zod';
import * as API from '../../api';
import { DatePicker, DocViewer, DraggableModal, Select, Toolbar, ToolbarLeft, ToolbarRight, Input as InputMe } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useOrderList, useWindowSize } from '../../hooks';
import { ROL } from '../../types';

const Form = z.object({
  faturaTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  faturaTipiId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  alisFaturaAciklama: z.string().nullish(),
});

export const BillStep1 = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();

  const [markets = []] = API.MARKETS.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [categories = []] = API.CATEGORY.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [brands = []] = API.BRAND.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehouses = []] = API.WAREHOUSES.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const {
    users = [],
    list = [],
    isAdmin,
    setSelectedUserId,
    loading,
    getList,
    columns,
    selectedKeyList,
    selectedList,
    onMultiSelectClick,
    depoyeri,
    setDepoyeri,
    pazaryeri,
    setPazaryeri,
    kategori,
    setKategori,
    marka,
    setMarka,
    urun,
    setUrun,
    siparisNo,
    setSiparisNo,
    siparisId,
    setSiparisId,
    siparisTarihi,
    setSiparisTarihi,
    isPdf,
    doc,
    byteArray,
    isDocModalOpen,
    setIsDocModalOpen,
    getDoc,
  } = useOrderList(true);
  const [billtypes = []] = API.BILLTYPE.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEfModalOpen, setIsEfModalOpen] = useState(false);
  const [file, setFile] = useState<UploadFile>();

  const { form, clear, fill } = useFillForm(Form, {
    faturaTarihi: new Date(),
    faturaTipiId: undefined,
    alisFaturaAciklama: ' ',
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const selectedKeyListSt = (selectedKeyList || []) as React.Key[];

  const handleOk = useCallback(async () => {
    const { success, data, error } = form.parse();
    if (success) {
      if (file && selectedList) {
        const siparisIdList = selectedList.map((order) => Number(order.siparisId));
        const date = new Date(data.faturaTarihi);
        date.setDate(15);
        date.setHours(10);
        date.setMinutes(10);
        date.setSeconds(10);
        date.setMilliseconds(10);
        await API.FILES.uploadAlisFatura(file, siparisIdList, date, Number(data.faturaTipiId), data.alisFaturaAciklama || ' ');

        getList();
        clear();
        handleCancel();
      }
    } else {
      form.setAllTouched();
    }
  }, [selectedList, file, form]);

  const handleEfOk = useCallback(async () => {
    const { success, data, error } = form.parse();
    if (success) {
      if (selectedList) {
        const siparisIdList = selectedList.map((order) => Number(order.siparisId));
        const date = new Date(data.faturaTarihi);
        date.setDate(15);
        date.setHours(10);
        date.setMinutes(10);
        date.setSeconds(10);
        date.setMilliseconds(10);
        await API.FILES.efatura(siparisIdList, date, Number(data.faturaTipiId));

        getList();
        clear();
        handleCancel();
      }
    } else {
      form.setAllTouched();
    }
  }, [selectedList, form]);

  const handleCancel = useCallback(() => {
    setFile(undefined);
    setIsDocModalOpen(false);
    setIsModalOpen(false);
    setIsEfModalOpen(false);
  }, []);

  const uploadProps: UploadProps = {
    accept: 'image/*,.pdf',
    onRemove: () => {
      setFile(undefined);
    },
    beforeUpload: (file) => {
      setFile(file);

      return false;
    },
    fileList: file ? [file] : [],
  };

  const approveLess = useCallback(async () => {
    if (selectedList) {
      const siparisIdList = selectedList.map((order) => Number(order.siparisId));
      await API.FILES.approveLess(siparisIdList);

      getList();
    }
  }, [selectedList]);

  const productByCategory = products.filter((product) => product.bhrKategoriModel?.kategoriId === kategori && product.bhrMarkaModel?.markaId === marka);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <Button onClick={getList} icon={<RetweetOutlined />}>
            Yenile
          </Button>
          {kullaniciModel.kullaniciTipi === ROL.ADMIN && (
            <SelectAnt
              className="w-full sm:w-64 md:max-w-[300px]"
              showSearch
              placeholder="Tedarikçi Seçiniz"
              options={users.map((user) => ({ ...user, label: `${user.kullaniciAdi} - ${user.ad} ${user.soyad}` }))}
              fieldNames={{ label: 'label', value: 'kullaniciId' }}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              allowClear
              onChange={setSelectedUserId}
            ></SelectAnt>
          )}

          <SelectAnt
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Depoyeri"
            options={warehouses}
            fieldNames={{ label: 'depoyeriAdi', value: 'depoyeriId' }}
            filterOption={(input, option) => (option?.depoyeriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={depoyeri}
            onChange={setDepoyeri}
            allowClear
          ></SelectAnt>
        </ToolbarLeft>
        <ToolbarRight classname="sm:flex-none">
          <Button className="w-full" type="primary" disabled={!(selectedList && selectedList.length > 0)} onClick={() => showModal()} icon={<CloudUploadOutlined />}>
            Fatura Yükle
          </Button>
          {kullaniciModel.kullaniciTipi === ROL.ADMIN && (
            <Button className="w-full" type="primary" disabled={!(selectedList && selectedList.length)} onClick={approveLess} icon={<CheckCircleOutlined />}>
              Fatura İhtiyacı Yok
            </Button>
          )}
          <Button className="w-full" type="primary" disabled={!(selectedList && selectedList.length > 0)} onClick={() =>  setIsEfModalOpen(true)} icon={<SendOutlined />}>
            E-Fatura
          </Button>
          <Button className="w-full" disabled={!(selectedList && selectedList.length === 1 && selectedList[0]?.alisFaturasiModel)} onClick={getDoc} icon={<EyeOutlined />}>
            Görüntüle
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
          <SelectAnt
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Pazaryeri"
            options={markets}
            fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }}
            filterOption={(input, option) => (option?.pazaryeriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={pazaryeri}
            onChange={setPazaryeri}
            allowClear
          ></SelectAnt>
          <SelectAnt
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Kategori"
            options={categories}
            fieldNames={{ label: 'kategoriAdi', value: 'kategoriId' }}
            filterOption={(input, option) => (option?.kategoriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={kategori}
            onChange={setKategori}
            allowClear
          ></SelectAnt>
          <SelectAnt
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Marka"
            options={brands}
            fieldNames={{ label: 'markaAdi', value: 'markaId' }}
            filterOption={(input, option) => (option?.markaAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={marka}
            onChange={setMarka}
            allowClear
          ></SelectAnt>
          <SelectAnt
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Ürün"
            options={productByCategory}
            fieldNames={{ label: 'urunAdi', value: 'urunId' }}
            filterOption={(input, option) => (option?.urunAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={urun}
            onChange={setUrun}
            allowClear
          ></SelectAnt>
          <Input className="w-full sm:w-64 md:max-w-[200px]" placeholder="Sipariş No" value={siparisNo} onChange={(e) => setSiparisNo(e.target.value)} allowClear></Input>
          <Input className="w-full sm:w-64 md:max-w-[120px]" placeholder="Kayıt No" value={siparisId} onChange={(e) => setSiparisId(e.target.value as unknown as number)} allowClear></Input>

          <DatePickerAnt.RangePicker
            placeholder={['Sipariş Tarihi Baş.', 'Bitiş Tarihi']}
            value={siparisTarihi}
            onChange={(value) => setSiparisTarihi(value as any)}
            allowClear={true}
            className="w-full sm:w-80"
            locale={locale}
            format="DD.MM.YYYY"
          />
        </ToolbarLeft>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={{
          defaultPageSize: 25,
          pageSizeOptions: [10, 25, 50],
          position: ['topRight'],
          showTotal: (total) => <div>Toplam Kayıt : {total}</div>,
        }}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => {
            if (isAdmin) {
              const key = record.siparisId as React.Key;
              if (selectedKeyListSt.includes(key)) {
                onMultiSelectClick(selectedKeyListSt.filter((_key) => _key !== key));
              } else {
                onMultiSelectClick([...selectedKeyListSt, key]);
              }
            }
          },
        })}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedKeyList || [],
          onChange: onMultiSelectClick,
        }}
      />

      {doc && (
        <Modal
          key={doc.dokumanId}
          width={'min-content'}
          footer={null}
          cancelText="Kapat"
          open={isDocModalOpen}
          onCancel={handleCancel}
          style={{ top: 40 }}
          bodyStyle={{ marginTop: 20, height: '86vh', overflow: 'auto' }}
        >
          {isPdf && <DocViewer doc={doc} byteArray={byteArray} />}
          {!isPdf && <img src={`data:image;base64,${byteArray}`}></img>}
        </Modal>
      )}

      <DraggableModal width={300} title="Fatura Yükleme" okText="Kaydet" cancelText="Kapat" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Fatura Tipi :</label>
          <Select options={billtypes} fieldNames={{ label: 'faturaTipiAdi', value: 'faturaTipiId' }} form={form} name="faturaTipiId" />

          <label className="whitespace-nowrap mt-2">Fatura Tarih :</label>
          <DatePicker picker="month" format="YYYY MMMM" form={form} name="faturaTarihi" />

          <label className="whitespace-nowrap">Açıklama :</label>
          <InputMe form={form} name="alisFaturaAciklama" />

          <Upload {...uploadProps} className="mt-4">
            <Button block icon={<UploadOutlined />}>
              Fatura Seç
            </Button>
          </Upload>
        </div>
      </DraggableModal>

      <DraggableModal width={300} title="Fatura Yükleme" okText="Kaydet" cancelText="Kapat" open={isEfModalOpen} onOk={handleEfOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Fatura Tipi :</label>
          <Select options={billtypes} fieldNames={{ label: 'faturaTipiAdi', value: 'faturaTipiId' }} form={form} name="faturaTipiId" />

          <label className="whitespace-nowrap mt-2">Fatura Tarih :</label>
          <DatePicker picker="month" format="YYYY MMMM" form={form} name="faturaTarihi" />
        </div>
      </DraggableModal>
    </div>
  );
};

BillStep1.path = 'bill-step-1';
BillStep1.title = 'Fatura Bekleyenler';
BillStep1.group = 'bills';
BillStep1.roles = [ROL.ADMIN, ROL.TEDARIKCI];
BillStep1.icon = <FieldTimeOutlined />;
