import dayjs from 'dayjs';
import React from 'react';

import { DatePicker as DatePickerAnt } from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';

type Props = {
  form: any;
  name: string;
  disabled?: boolean;
  className?: string;
  picker?: string;
  format?: string;
  showTime? : boolean;
};

export const DatePicker = ({ form, name, disabled, className, showTime, picker = 'date', format = 'DD/MM/YYYY' }: Props) => {
  return (
    <div className={`relative ${className}`}>
      <DatePickerAnt
        showTime={showTime}
        picker={picker}
        className="w-full md:max-w-[300px]"
        placeholder="Seçiniz"
        format={format}
        inputReadOnly={true}
        disabled={disabled}
        locale={locale}
        allowClear={false}
        {...form.props(name)}
        value={dayjs(form.value[name])}
        onChange={(date) => form.setField(name)(date?.toDate())}
      ></DatePickerAnt>
      {form.toucheds[name] && <small className="left-0 -bottom-4 text-red-500">{form.errors[name]}</small>}
    </div>
  );
};
