import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileExcelOutlined,
  FileUnknownOutlined,
  MailOutlined,
  PlusCircleOutlined,
  ProfileOutlined,
  RetweetOutlined,
  RollbackOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Button, DatePicker as DatePickerAnt, Input, Modal, Select, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import * as API from '../../api';

import { z } from 'zod';
import { DocViewer, DraggableModal, InputNumber, OrderUpdate, Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useForm, useOrderList, useWindowSize } from '../../hooks';
import { EVET_HAYIR, ROL, SIPARIS_STATU, SiparisModel } from '../../types';
import { excelExportOrderList } from '../../utils';
import { NewOrder } from './NewOrder';

const SatisForm = z.object({
  satisFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const OrderListStep2 = () => {
  const { sm } = useWindowSize();
  const kullaniciModel = useContext(UserContext);

  const [markets = []] = API.MARKETS.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [categories = []] = API.CATEGORY.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [brands = []] = API.BRAND.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehouses = []] = API.WAREHOUSES.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const [fillOrder, setFillOrder] = useState<SiparisModel | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isSatisModalOpen, setIsSatisModalOpen] = useState(false);

  const satisForm = useForm(SatisForm, {
    satisFiyat: undefined,
  });

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
    setIsUpdateModalOpen(false);
    setIsSatisModalOpen(false);
    satisForm.reset();
  }, [setIsModalOpen]);

  const {
    users = [],
    setSelectedUserId,
    siparisStatu,
    setSiparisStatu,
    odemeYapildi,
    odemeGeriAl,
    setOdemeYapildi,
    faturaOnay,
    setFaturaOnay,
    efaturaMi,
    setEfaturaMi,
    depoyeri,
    setDepoyeri,
    pazaryeri,
    setPazaryeri,
    kategori,
    setKategori,
    marka,
    setMarka,
    urun,
    setUrun,
    siparisNo,
    setSiparisNo,
    siparisId,
    setSiparisId,
    siparisTarihi,
    setSiparisTarihi,
    faturaTarihi,
    setFaturaTarihi,
    odemeTarih,
    setOdemeTarihi,
    isAdmin,
    list,
    loading,
    getList,
    columns,
    selectedKeyList,
    selectedList,
    onMultiSelectClick,
    pasif,
    satis,
    satisIptal,
    odemeYap,
    downloadAll,
    isPdf,
    doc,
    byteArray,
    isDocModalOpen,
    setIsDocModalOpen,
    getDoc,
  } = useOrderList(false,false,true);

  const satisHandleOk = useCallback(async () => {
    const { data, success } = satisForm.parse();
    if (success) {
      const satisList = selectedList?.map((order) => ({
        ...order,
        satisFiyat: data.satisFiyat,
      })) as SiparisModel[];

      await satis(satisList);

      handleCancel();
      satisForm.reset();
    } else {
      satisForm.setAllTouched();
    }
  }, [satisForm, selectedList]);

  const docHandleCancel = useCallback(() => {
    setIsDocModalOpen(false);
  }, [setIsDocModalOpen]);

  const excel = useCallback(() => {
    if (list && list.length > 0) {
      excelExportOrderList(list);
    }
  }, [list]);

  const productByCategory = products.filter((product) => product.bhrKategoriModel?.kategoriId === kategori && product.bhrMarkaModel?.markaId === marka);

  const selectedKeyListSt = (selectedKeyList || []) as React.Key[];

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
        <Button size="small" onClick={() => getList()} icon={<RetweetOutlined />}>
            Sorgula
          </Button>
          {isAdmin && (
            <Select
              className="w-full sm:w-64 md:max-w-[100px]"
              showSearch
              placeholder="Statü Seçiniz"
              options={[
                { value: SIPARIS_STATU.SIP_DEPO, label: 'DEPODA' },
                { value: SIPARIS_STATU.SIP_SATIS, label: 'SATILDI' },
              ]}
              fieldNames={{ value: 'value', label: 'label' }}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              allowClear
              value={siparisStatu}
              onChange={setSiparisStatu}
            ></Select>
          )}
          <Select
            className="w-full sm:w-64 md:max-w-[160px]"
            showSearch
            placeholder="Ödeme Durumu"
            options={[
              { value: EVET_HAYIR.EVET, label: 'Ödeme Yapıldı' },
              { value: EVET_HAYIR.HAYIR, label: 'Ödeme Yapılmadı' },
            ]}
            fieldNames={{ value: 'value', label: 'label' }}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            value={odemeYapildi}
            onChange={setOdemeYapildi}
            allowClear
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[160px]"
            showSearch
            placeholder="Fatura Durumu"
            options={[
              { value: EVET_HAYIR.EVET, label: 'Fatura Onaylı' },
              { value: EVET_HAYIR.HAYIR, label: 'Fatura Onaysız' },
            ]}
            fieldNames={{ value: 'value', label: 'label' }}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            value={faturaOnay}
            onChange={setFaturaOnay}
            allowClear
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[160px]"
            showSearch
            placeholder="E-Fatura Mı"
            options={[
              { value: EVET_HAYIR.EVET, label: 'Evet' },
              { value: EVET_HAYIR.HAYIR, label: 'Hayır' },
            ]}
            fieldNames={{ value: 'value', label: 'label' }}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            value={efaturaMi}
            onChange={setEfaturaMi}
            allowClear
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Depoyeri"
            options={warehouses}
            fieldNames={{ label: 'depoyeriAdi', value: 'depoyeriId' }}
            filterOption={(input, option) => (option?.depoyeriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={depoyeri}
            onChange={setDepoyeri}
            allowClear
          ></Select>
        </ToolbarLeft>
      </Toolbar>
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
          <DatePickerAnt.RangePicker
            placeholder={['Sipariş Tar. Baş.', 'Bitiş Tar.']}
            value={siparisTarihi}
            onChange={(value) => setSiparisTarihi(value as any)}
            allowClear={true}
            className="w-full sm:w-72"
            locale={locale}
            format="DD.MM.YYYY"
          />
          <DatePickerAnt
            placeholder="Fatura Tarihi"
            value={faturaTarihi}
            onChange={(value) => setFaturaTarihi(value)}
            allowClear={true}
            className="w-full sm:w-40"
            picker="month"
            locale={locale}
            format="YYYY MMMM"
          />
          <DatePickerAnt.RangePicker
            placeholder={['Ödeme Tar. Baş.', 'Bitiş Tar.']}
            value={odemeTarih}
            onChange={(value) => setOdemeTarihi(value as any)}
            allowClear={true}
            className="w-full sm:w-72"
            locale={locale}
            format="DD.MM.YYYY"
          />
        </ToolbarLeft>
      </Toolbar>
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
          <Select
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Pazaryeri"
            options={markets}
            fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }}
            filterOption={(input, option) => (option?.pazaryeriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={pazaryeri}
            onChange={setPazaryeri}
            allowClear
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Kategori"
            options={categories}
            fieldNames={{ label: 'kategoriAdi', value: 'kategoriId' }}
            filterOption={(input, option) => (option?.kategoriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={kategori}
            onChange={setKategori}
            allowClear
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Marka"
            options={brands}
            fieldNames={{ label: 'markaAdi', value: 'markaId' }}
            filterOption={(input, option) => (option?.markaAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={marka}
            onChange={setMarka}
            allowClear
          ></Select>
          <Select
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Ürün"
            options={productByCategory}
            fieldNames={{ label: 'urunAdi', value: 'urunId' }}
            filterOption={(input, option) => (option?.urunAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={urun}
            onChange={setUrun}
            allowClear
          ></Select>
          <Input className="w-full sm:w-64 md:max-w-[200px]" placeholder="Sipariş No" value={siparisNo} onChange={(e) => setSiparisNo(e.target.value)} allowClear></Input>
          <Input
            className="w-full sm:w-64 md:max-w-[120px]"
            placeholder="Kayıt No"
            value={siparisId}
            onChange={(e) => setSiparisId(e.target.value as unknown as number)}
            allowClear
          ></Input>
        </ToolbarLeft>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={{
          defaultPageSize: 25,
          pageSizeOptions: [10, 25, 50],
          position: ['topRight'],
          showTotal: (total) => <div>Toplam Kayıt : {total}</div>,
        }}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => {
            if (isAdmin) {
              const key = record.siparisId as React.Key;
              if (selectedKeyListSt.includes(key)) {
                onMultiSelectClick(selectedKeyListSt.filter((_key) => _key !== key));
              } else {
                onMultiSelectClick([...selectedKeyListSt, key]);
              }
            }
          },
        })}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedKeyList || [],
          onChange: onMultiSelectClick,
        }}
      />

      <Modal width={480} footer={null} cancelText="Kapat" open={isModalOpen} onCancel={handleCancel} bodyStyle={{ marginTop: 20 }}>
        <NewOrder
          key={list?.length}
          fillOrder={fillOrder}
          onSaveDone={() => {
            getList();
            setFillOrder(null);
            onMultiSelectClick([]);
            setIsModalOpen(false);
          }}
        />
      </Modal>
      <Modal width={480} footer={null} cancelText="Kapat" open={isUpdateModalOpen} onCancel={handleCancel} style={{ marginTop: -70 }} bodyStyle={{ marginTop: 20 }}>
        <OrderUpdate
          open={isUpdateModalOpen}
          order={fillOrder}
          onSave={async (order: SiparisModel) => {
            await API.ORDERS.update(order);
            setIsUpdateModalOpen(false);
            setFillOrder(null);
            onMultiSelectClick([]);
            getList();
          }}
        />
      </Modal>
      <DraggableModal width={300} title="Satış" okText="Kaydet" cancelText="Kapat" open={isSatisModalOpen} onOk={satisHandleOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Satış Fiyat :</label>
          <InputNumber form={satisForm} name="satisFiyat" min={1} />
        </div>
      </DraggableModal>
      {doc && (
        <Modal
          key={doc.dokumanId}
          width={'min-content'}
          footer={null}
          cancelText="Kapat"
          open={isDocModalOpen}
          onCancel={docHandleCancel}
          style={{ top: 40 }}
          bodyStyle={{ marginTop: 20, height: '86vh', overflow: 'auto' }}
        >
          {isPdf && <DocViewer doc={doc} byteArray={byteArray} />}
          {!isPdf && <img src={`data:image;base64,${byteArray}`}></img>}
        </Modal>
      )}
    </div>
  );
};

OrderListStep2.path = 'order-list-step-2';
OrderListStep2.title = 'Sahipsizler';
OrderListStep2.group = 'supplier';
OrderListStep2.roles = [ROL.TEDARIKCI, ROL.ADMIN];
OrderListStep2.icon = <FileUnknownOutlined />;
