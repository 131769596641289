import React from 'react';
import { SiparisModel } from '../types';

const a = {
  data: [
    { kargoFirmasiId: 8, kargoFirmasiAdi: 'AGT' },
  ],
  message: 'İşlem başarılı.',
  code: null,
  sonuc: true,
};

const cargoBaseUrl: Record<number, string> = {
  1: 'https://www.yurticikargo.com/tr/online-servisler/gonderi-sorgula?code=',
  2: 'http://kargotakip.araskargo.com.tr/mainpage.aspx?code=',
  3: 'https://gonderitakip.ptt.gov.tr/Track/Verify?q=',
  4: 'https://kargotakip.mngkargo.com.tr/?takipNo=',
  5: 'https://www.suratkargo.com.tr/KargoTakip/?kargotakipno=',
  6: 'https://www.ups.com.tr/WaybillSorgu.aspx?Waybill=',
  7: 'https://esube.kolaygelsin.com/shipments?trackingId=',
  8: 'http://kargotakip.araskargo.com.tr/mainpage.aspx?code=',
  9: 'https://www.hepsijet.com/gonderi-takibi/',
  10: 'https://kargotakip.trendyol.com/?orderNumber=',
};

export const CargoUrl = ({ cargoId, cargoNo }: { cargoId?: number; cargoNo?: string }) => {
  if (cargoId && cargoNo && cargoBaseUrl[cargoId]) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={`${cargoBaseUrl[cargoId]}${cargoNo}`}>
        {cargoNo}
      </a>
    );
  }

  return <span>{cargoNo}</span>;
};
