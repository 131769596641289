import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { App } from './App';

const container = document.getElementById('app');
const root = createRoot(container);

root.render(<App />);
