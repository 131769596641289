import React from 'react';
import { Link } from 'react-router-dom';

export const pageToMenuItem = ([_, Page]) => ({
  key: Page.path,
  label: <Link to={Page.path}>{Page.title}</Link>,
  icon: Page.icon,
  breadcrumbtitle: Page.title,
  roles: Page.roles,
  group: Page.group,
  title: '',
});
